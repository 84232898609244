import {React, useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
// import Web3;
// require("web3");
import { ContractInteractor } from 'ether-gui';
import { ethers } from 'ethers';
import Abi from './Abi.json'
import ierc from './ierc.json'

// ...
// inside your component
function App() {
  // const Web3 = require('web3');  
  // let web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
  // const contract = new Contract(contractAddress, contractAbi, signerOrProvider);
  // async function getContract() {
  //   var Web3 = require('web3');
  //   var abi = splits.abi
  //   var address =""
  //   var Contract = new Web3.eth.Contract(abi, address)
    
  // }
  var contract;
  var UsdcContract;
  const [balance, setBalance] = useState(0)
  async function Mint() {
    if (typeof window.ethereum !== 'undefined') {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      // create a wallet that signs transaction so metamask doesn't pop up everytime.
      let privateKey = '01b5d334dbd30dc4bff670911dde15998f420aac5dda476fc51c315ba9c59f35'
      let wallet = new ethers.Wallet(privateKey, provider)
      
      // our disperser contract instance
      contract = new ethers.Contract("0xC50E9d1560d2Ca1f0971498d5a75B022b1E155b2", Abi, wallet);
      // usdc contract to get balances, TODO: add weth balances too
      UsdcContract = new ethers.Contract("0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174", ierc, wallet);
      
      const { chainId } = await provider.getNetwork()

      if (chainId === 137) {
        let balances = await UsdcContract.balanceOf("0xC50E9d1560d2Ca1f0971498d5a75B022b1E155b2");
        let bn = ethers.BigNumber.from(balances)
        setBalance(bn.toNumber()/1e6)
      }else {
        alert('change to polygon to see token balances')
      }
      
      try {
        // using usdc on polygon for testing purposes, WETH address: 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2
        await contract.disperseToken("0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174");
        // contract.on("Created", (user, tokenAddress) => {
        //   alert(`your token has been deployed, here is your token address ${tokenAddress}`)
        // })
      } catch (error) {
        console.log(error)
      }
      
    } 
    
  }

  // call disperse on load, if we know specific token we're dispersing for, perhaps using even listener will be better.
  useEffect(() => {
    Mint()
  }, [])

  // for gui purposes
  let provSigner = new ethers.providers.Web3Provider(window.ethereum).getSigner();
  const guicontract = new ethers.Contract("0xC50E9d1560d2Ca1f0971498d5a75B022b1E155b2", Abi, provSigner);
  


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          {/* Edit <code>src/App.js</code> and save to reload. */}
        </p>
        <a
          className="App-link"
          href="https://musiswap.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          MusiSplit
        </a>
      </header>
      <div>
        <div>
          <p className="tw-text-red-700">Contract Balances</p>
          <span>Usdc: {balance}</span>
          <p> Weth: 0 </p>
          
        </div>
      </div>
      <ContractInteractor contract={guicontract} />


    </div>
)
}

export default App;
